import './src/styles/global.css';

import { Script, type GatsbyBrowser } from 'gatsby';
import { PostHogProvider } from 'posthog-js/react';
import React from 'react';
import {
  Query,
  QueryClient,
  QueryClientProvider,
  QueryKey,
  focusManager,
} from 'react-query';
import { broadcastQueryClient } from 'react-query/broadcastQueryClient-experimental';
import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental';
import { ReactQueryDevtools } from 'react-query/devtools';
import { persistQueryClient } from 'react-query/persistQueryClient-experimental';

import { isSSR, isSafari } from './src/utils';

// import { onWindowFocus } from './src/utils/onWindowFocus';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        const retry = [429, 504].includes((error as any)?.cause) && failureCount < 6;
        console.log(`retry ${retry}`);
        return retry;
      },
      retryDelay: (attemptIndex) => {
        const gap = 1000 * 2 ** (1 + Math.min(attemptIndex, 3));
        const delay = gap + Math.floor(Math.random() * gap);
        console.log(`retry# ${attemptIndex}: ${delay}`);
        return delay;
      },
    },
  },
});

if (typeof window !== 'undefined') {
  // focusManager.setEventListener(onWindowFocus);

  const localStoragePersistor = createWebStoragePersistor({
    storage: window.localStorage,
  });

  persistQueryClient({
    queryClient,
    persistor: localStoragePersistor,
    dehydrateOptions: {
      shouldDehydrateQuery: (query: Query<any, any, any, QueryKey>) => {
        if (typeof query.queryKey !== 'string') {
          return true;
        }

        if (query.queryKey.startsWith('/cardcatalog/connectorstatus:')) return false; // don't persist connector status information

        // experimental: do not store/persist (= dehydrate) results with error code

        /* if ((!!query.state?.data?.ErrorCode) || query.state.error?.message) {
          // console.log("xxx",query.queryKey,query.state?.data?.ErrorCode, query.state.data.Data?.ErrorText )
          return false; // do not persist queries with ErrorCode
        } */

        return true;
      },
    },
  });
}

broadcastQueryClient({
  queryClient,
  broadcastChannel: 'adenin-digital-assistant',
});

if (typeof document !== 'undefined' && typeof navigator !== 'undefined' && /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)) {
  document.onreadystatechange = () => {
    if (document.readyState !== 'complete') return;
    document.documentElement.classList.add('platform-apple');
  };
}

export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
  if (!sessionStorage.length) {
    console.log('Ask other tabs for session storage');
    localStorage.setItem('getSessionStorage', Date.now().toString());
  }

  window.addEventListener('storage', (event) => {
    if (event.key === 'getSessionStorage') {
      console.log('Some tab asked for the sessionStorage -> send it');

      localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
      localStorage.removeItem('sessionStorage');
    }

    if (event.key === 'sessionStorage' && !sessionStorage.length) {
      console.log('sessionStorage is empty -> fill it');

      if (!event.newValue) {
        return;
      }

      const data = JSON.parse(event.newValue);

      Object.keys(data).forEach((key) => {
        sessionStorage.setItem(key, data[key]);
      });
    }
  });

  if (process.env.NODE_ENV !== 'production') {
    import('@welldone-software/why-did-you-render').then((wdyr) => {
      wdyr.default(React, {
        trackAllPureComponents: true,
        exclude: [
          /^RouterImpl/,
        ],
      });
    });
  }

  // BroadcastChannel implementation, doesn't seem to work:

  /* const StorageEvents = {
    CHANNEL: 'session_storage_transfer',
    REQUEST: 'storage_request',
    TRANSFER: 'storage_transfer'
  };

  const channel = new BroadcastChannel(StorageEvents.CHANNEL);
  console.log('Subscribing to sessionStorage transfer');

  channel.onmessage = function (event) {
    if (event.type == StorageEvents.REQUEST) {
      console.log('Received sessionStorage request, sending any existing sessionStorage to channel');
      channel.postMessage({
        type: StorageEvents.TRANSFER,
        payload: sessionStorage.length ? JSON.stringify(sessionStorage) : null
      });
    }

    if (event.type == StorageEvents.TRANSFER) {
      console.log('Received sessionStorage transfer');

      if (!event.data.payload || sessionStorage.length) return;

      console.log('Existing sessionStorage is empty, adding transfered value');

      const receivedStorage = JSON.parse(event.data.payload);

      for (const key in receivedStorage) {
        sessionStorage.setItem(key, event.data[key]);
      }
    }
  };

  if (!sessionStorage.length) {
    console.log('No existing sessionStorage, requesting transfer');
    channel.postMessage({
      type: StorageEvents.REQUEST
    });
  } */
};

const posthogOptions = {
  api_host: 'https://eu.posthog.com',
  opt_in_site_apps: true,
  session_recording: {
    maskAllInputs: false,
    maskInputOptions: {
      password: true,
    },
  },
};

const MaybePostHogProvider = isSSR ? ({ children }: { children: JSX.Element }) => children : PostHogProvider;

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => (
  <MaybePostHogProvider
    apiKey="phc_1mEwwsp16wREnGrf1UU67YmhTOmLioNpEyaYKQzRnUi"
    options={posthogOptions}
  >
    <QueryClientProvider client={queryClient}>
      {element}
      <ReactQueryDevtools initialIsOpen={false} />
      <Script src="/iframeResizer.contentWindow.min.js" />
      {/* <Script id="_cio">
        {`var _cio = _cio || [];
        (function() {
            var a,b,c;a=function(f){return function(){_cio.push([f].
            concat(Array.prototype.slice.call(arguments,0)))}};b=["load","identify",
            "sidentify","track","page","on","off"];for(c=0;c<b.length;c++){_cio[b[c]]=a(b[c])};
            var t = document.createElement('script'),
                s = document.getElementsByTagName('script')[0];
            t.async = true;
            t.id    = 'cio-tracker';
            t.setAttribute('data-site-id', '21af621b9e476925a95a');
            t.setAttribute('data-use-array-params', 'true');
            t.setAttribute('data-use-in-app', 'true');
            t.src = 'https://assets.customer.io/assets/track.js';
            //If your account is in the EU, use:
            //t.src = 'https://assets.customer.io/assets/track-eu.js'
            s.parentNode.insertBefore(t, s);
        })();`}
      </Script> */}
      {/* <Script>
        {`
          (function(apiKey){
            (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
            v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
              o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
              y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
              z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
          })('e2c20e98-8845-4416-6e0d-42c04df2f99b');
        `}
      </Script> */}
    </QueryClientProvider>
  </MaybePostHogProvider>
);

import { navigate } from 'gatsby';
import { useEffect, useRef } from 'react';
import { dispatch } from 'use-bus';

export const isSSR = typeof window === 'undefined';

export const isMobilePlatform = isSSR
  ? false
  : ((userAgent = navigator.userAgent.toLowerCase()) => window.location.search.includes('mode=mobile')
    || userAgent.includes('android')
    || userAgent.includes('iphone')
    || userAgent.includes('ipad')
    || userAgent.includes('ipod'))();

export const isSafari = isSSR
  ? false
  : ((userAgent = navigator.userAgent.toLowerCase()) => userAgent.includes('safari/') && !userAgent.includes('chrome/') && !userAgent.includes('chromium/'))();

export const isFirefox = isSSR
  ? false
  : ((userAgent = navigator.userAgent.toLowerCase()) => userAgent.includes('firefox/') && !userAgent.includes('seamonkey/'))();

export const isE2E = isSSR
  ? false
  : ((userAgent = navigator.userAgent.toLowerCase()) => userAgent.includes('playwright'))();

export const objectToQueryString = (obj) => {
  const paramNames = Object.getOwnPropertyNames(obj);
  let queryString = '';

  for (let i = 0; i < paramNames.length; i++) {
    if (i !== 0) {
      queryString += '&';
    }

    queryString += `${paramNames[i]}=${obj[paramNames[i]]}`;
  }

  return queryString;
};

export const searchParamsToObject = (searchParams) => {
  const paramData = {};

  searchParams.forEach((v, k) => {
    paramData[k] = v;
  });

  return paramData;
};

export const navigateSpa = (to, options) => {
  if (!location.pathname.includes('modal') || to.includes('assistant') || to.startsWith('http') || to.includes('designer') || to.includes('notebook')) {
    dispatch('iframe.close');
    navigate(to, options);
    return;
  }

  dispatch({ type: 'iframe.open', url: to });
};

export function getCookie(cname) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export function openAuthenticationWindow(url, title, auth = true) {
  const popupWidth = 650;
  const popupHeight = 750;

  let win = window.top;

  // Detect if window.top is usable
  try {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    win.outerWidth;
  } catch {
    win = window;
  }

  const left = win.outerWidth / 2 + win.screenX - (popupWidth / 2);
  const top = win.outerHeight / 2 + win.screenY - (popupHeight / 2);

  return window.open(url, `${auth ? 'A' : 'Una'}uthorize ${title}`, `popup=1, location=1, width=${popupWidth}, height=${popupHeight}, left=${left}, top=${top}`);
}

export function isInIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

function isValidDate(d) {
  const date = new Date(d);

  if (Object.prototype.toString.call(date) === '[object Date]') {
    if (isNaN(date)) {
      return false;
    }

    return true;
  }

  return false;
}

export function findFieldValue(data, rootObject, fieldName) {
  const elements = data[rootObject];
  let value = null;

  if (Array.isArray(elements) && elements.length) {
    value = elements[0][fieldName];
  }

  // Don't check validity for now
  /* if (!isValidDate(date)) {
    return null;
  } */

  return value;
}

export function hasNotificationDot(data, metadata) {
  if (data?.ErrorCode !== 0 || !metadata?.UserActions?.hasUnread) {
    return false;
  }

  if (metadata?.configuration?.notificationMode === 'listDate') {
    // Card has notification when no read version is saved
    if (!metadata.ReadVersion) {
      return true;
    }

    const lastMode = metadata.ReadVersion.split('-')[0];

    // Card has notification if mode was changed since last read version submitted (?)
    if (lastMode !== 'listDate') {
      return true;
    }

    const lastDate = metadata.ReadVersion.replace('listDate-', '');
    const date = findFieldValue(data.Data, metadata.configuration.rootObject, metadata.configuration.fieldName);

    // If date was found, compare
    if (date) {
      return lastDate < date;
    }

    // Card has no notification if no field was found
    return false;
  }

  if (data?.Data?._hash.startsWith('<') && metadata?.ReadVersion) {
    return metadata?.ReadVersion.substring(1) < data?.Data?._hash.substring(1);
  }

  return metadata?.ReadVersion !== data?.Data?._hash;
}

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function setAppBadge(number) {
  if (isSSR) {
    return;
  }

  if (window.Favico) {
    const favicon = new window.Favico({
      animation: 'popFade',
      bgColor: typeof number === 'undefined' ? '#ffff00' : '#0178d5',
    });

    favicon.badge(typeof number === 'undefined' ? '!' : number);
  }

  if (navigator.setAppBadge) {
    if (number === 0) {
      navigator.clearAppBadge();
    } else {
      navigator.setAppBadge(number);
    }
  }
}

/* eslint-disable @typescript-eslint/naming-convention */

const isBrowser = typeof window !== 'undefined';

let _hostUrl = '';
let _usePkce = false;
let _clientId = '';
let _tenantId = '';
let cardsAPIUrl = '';

let url = 'https://stage.adenin.com/api-79j7V/'; // @amasis default demo tenant
let searchUrl = '/app/testing/google-cse-demo?q=%s';

if (process.env.GATSBY_API_URL) {
  searchUrl = undefined;
  url = process.env.GATSBY_API_URL;
}

// use @adenin tenant custom url
if (isBrowser && window.location.host === 'adenin.digitalassistant.app') {
  url = 'https://app.adenin.com/api-7EMZP';
  console.log('use @adenin tenant custom url');
}

if (url.endsWith('/')) {
  url = url.substring(0, url.length - 1);
}

cardsAPIUrl = url;
if (cardsAPIUrl.indexOf('app.adenin.com') > 0) cardsAPIUrl = cardsAPIUrl.replace('app.adenin.com', 'cards-api.adenin.com'); // use dedicated cards-api host in prod

if (process.env.GATSBY_ENV === 'platform') {
  searchUrl = undefined;
  url = '/api';
  cardsAPIUrl = url;
  if (isBrowser && window.location.origin.indexOf('app.adenin.com') > 0) cardsAPIUrl = 'https://cards-api.adenin.com/api';
} else {
  _usePkce = process.env.GATSBY_USE_PKCE || true;
  _clientId = process.env.GATSBY_CLIENT_ID || '8uog5nrra8tpkyp9czq1pg7sq2w1klvv';

  const urlObj = new URL(url);
  _hostUrl = urlObj.origin;
  _tenantId = urlObj.pathname.replace('/api', '').replace('-', '');
}

export const apiUrl = url;
export const hostUrl = _hostUrl;
export const tenantId = _tenantId;

export const defaultFetchOpts = {
  mode: 'cors',
  headers: {
    'X-Requested-By': 'Digital Assistant Client',
    'X-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
};

export const pkceSettings = {
  name: 'platform',
  clientId: _clientId,
  endpoints: {
    authorize: `${hostUrl}/oauth2/authorize`,
    token: `${hostUrl}/oauth2/token`,
  },
  scopes: [],
};

// If we want to create a session from provided tokens
// We have to override PKCE settings from platform or independently hosted configs
if (typeof window !== 'undefined') {
  const storedToken = sessionStorage.getItem('pkce_token');
  if (storedToken) {
    try {
      const token = JSON.parse(storedToken);
      if (token?.access_token) {
        _usePkce = true;
      }
    } catch { /* empty */ }
  }
}

const config = {
  isBrowser,
  apiUrl,
  cardsAPIUrl,
  hostUrl,
  tenantId,
  defaultFetchOpts,
  pkceSettings,
  siteTitle: 'adenin',
  clientAuthFlow: false,
  clientTokenExpiration: 3600,
  useTokenAuth: _usePkce, // should the SPA use regular session cookie or PKCE oauth flow
  oidcProvider: null, // which (if any) OIDC provider name to use for login
  boardWidthScale: 1, // when != 1 all card widths are multiplied by this scale factor
  boardHeightScale: 1, // when != 1 all card heights are multiplied by this scale factor
  boardDisableBackground: false, // disables the placeholder grid units on board background
  colorScheme: 'auto', // css prefers-color-scheme setting. options: 'auto' (default), 'light', 'dark'
  defaultPath: '/app/assistant/grid-board',
  statusCodesForceLogin: [401],
  directoryUrl: 'https://www.adenin.com',
  searchUrl,
};

if (config.useTokenAuth && pkceSettings.name !== 'platform') {
  defaultFetchOpts.headers['X-Bearer-Type'] = pkceSettings.name;
}

// make global for easier debugging
if (isBrowser) {
  window.$at = window.$at || {};
  window.$at.config = config;
}

export default config;
